@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800&display=swap");
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.overflowHide {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.overflowHide::-webkit-scrollbar {
  display: none;
}
.overflowX {
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.overflowX::-webkit-scrollbar {
  display: none;
}

@layer components {
  .bg-grad {
    @apply bg-gradient-to-b from-[#008E56] to-[#FFF62C];
  }
  .max-width1 {
    @apply max-w-7xl mx-auto p-4;
  }
  .text-pry {
    @apply text-[#FFF62C];
  }
  .text-sec {
    @apply text-[#008E56];
  }
  .bg-pry {
    @apply bg-[#FFF62C];
  }
  .bg-sec {
    @apply bg-[#008E56];
  }
  .bg-pry2 {
    @apply bg-[#E3FFDC];
  }
  .bg-sec2 {
    @apply bg-[#055429];
  }
  .text-pry2 {
    @apply text-[#E3FFDC];
  }
  .text-sec2 {
    @apply text-[#055429];
  }
}
